import React from 'react';
import Flag from './Flag/Flag';
import TagList from './TagList';
import useSiteMetadata from '../hooks/use-site-config';
import { Bull, ReadingTime } from './Commons';

import './styles/PostListItem.scss';

const PostsListItem = props => {
  const { title, excerpt, slug, language, tags, timeToRead } = props;
  const { defaultLang } = useSiteMetadata();

  return (
    <article>
      <header>
        <h2>
          <a className="toPost" href={`/${slug}`}>
            {defaultLang !== language && <Flag language={language} />}
            {title}
          </a>
        </h2>
      </header>
      <section>
        <p dangerouslySetInnerHTML={{ __html: excerpt }} />
      </section>
      <footer>
        <div className="footerLine">
          <ReadingTime min={timeToRead} />
          <Bull />
          <TagList tags={tags} />
        </div>
        <a
          className="readMore"
          href={`/${slug}`}
          aria-label={`View ${title} article`}
        >
          Read post ›
        </a>
      </footer>
    </article>
  );
};
export default PostsListItem;
